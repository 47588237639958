import { useState } from "react"

import { DeviceListPopover } from "src/components/Dashboard/DashboardWidgets/DeviceListPopover"
import {
  TAlertLevel,
  WidgetCard,
} from "src/components/Dashboard/DashboardWidgets/WidgetCard"
import { DEVICE_LIST_LIMIT_MAX } from "src/data/devices/logic/deviceConstants"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { getStatefulHomesWithDevices } from "src/data/homes/logic/homeUtil"
import { IStatefulHomeWithDevices } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import ImportantIcon from "src/ui/icons/eventIcons/fire-alarm.svg"

export function WidgetFireCo() {
  const { org } = useOrganization()
  const { t, langKeys } = useTranslate()

  const nbrHomesLimit = 4
  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      fire_or_co_alarm_ongoing_filter: true,
      limit: nbrHomesLimit,
    },
  })
  const homes = fetchHomes.data?.homes
  const homesTotalCount = fetchHomes.data?.paging.total_count ?? 0

  const alertLevel: TAlertLevel = homesTotalCount > 0 ? "red" : "green"

  const fetchDevices = useFetchDevices({
    orgId: org.id,
    filter: {
      home_ids: homes?.map((h) => h.home_id),
      // a home can have multiple devices, hence limit need to be maxed out:
      limit: DEVICE_LIST_LIMIT_MAX,
    },
    options: { enabled: homesTotalCount > 0 },
  })
  const devices = fetchDevices.data?.devices

  const homesWithDevices = getStatefulHomesWithDevices(
    homes || [],
    devices || []
  )

  const loading = fetchHomes.isLoading || fetchDevices.isInitialLoading

  return (
    <FireCoWidgetCard
      homesWithDevices={homesWithDevices}
      totalCount={homesTotalCount}
      alertLevel={alertLevel}
      label={t(langKeys.fire_and_co_alarm_events_title)}
      loading={loading}
      showLink={homesTotalCount > nbrHomesLimit}
    />
  )
}

function FireCoWidgetCard({
  homesWithDevices,
  totalCount,
  alertLevel,
  label,
  loading,
  showLink,
}: {
  homesWithDevices: IStatefulHomeWithDevices[]
  totalCount: number
  alertLevel: TAlertLevel
  label: string
  loading: boolean
  showLink: boolean
}) {
  const { t } = useTranslate()
  const { isSuspended } = useDunning()

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  const linkTo = showLink
    ? Routes.Homes.location({
        fire_or_co_alarm_ongoing_filter: true,
      })
    : null

  return (
    <div>
      <WidgetCard
        alertLevel={alertLevel}
        number={totalCount}
        title={label}
        icon={ImportantIcon}
        iconSize={40}
        onClick={handleClick}
        disabled={isSuspended}
      />

      {open && (
        <DeviceListPopover
          title={label}
          emptyStateDescription={t(
            langKeys.ongoing_fire_and_co_events_empty_state
          )}
          homesWithDevices={homesWithDevices}
          loading={loading}
          anchorEl={anchorEl}
          onClose={handleClose}
          linkTo={linkTo}
        />
      )}
    </div>
  )
}
