import styled from "styled-components"

import { Popover } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function WidgetPopover({
  children,
  anchorEl,
  onClose,
}: {
  anchorEl: HTMLElement | null
  onClose?: () => void
  children: React.ReactNode
}) {
  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      {children}
    </Popover>
  )
}

export function WidgetSkeleton({ title }: { title: React.ReactNode }) {
  return (
    <SkeletonBox>
      <TitleBox>
        <MText variant="bodyS" color="secondary">
          {title}
        </MText>
      </TitleBox>
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
    </SkeletonBox>
  )
}

export function WidgetEmptyState({
  description,
}: {
  description: React.ReactNode
}) {
  return (
    <EmptyStateBox>
      <CheckCircleIcon />
      <MText variant="bodyS" color="secondary">
        {description}
      </MText>
    </EmptyStateBox>
  )
}

const SkeletonBox = styled.div`
  width: 500px;
  padding: ${spacing.M};
`

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  margin-bottom: 0;
  height: 28px;
`

const TitleBox = styled.div`
  margin-bottom: ${spacing.S};
`

const EmptyStateBox = styled.div`
  width: 300px;
  padding: ${spacing.L} ${spacing.M};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: ${spacing.XS};
`
