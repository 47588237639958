import { useState } from "react"

import { DeviceListPopover } from "src/components/Dashboard/DashboardWidgets/DeviceListPopover"
import {
  TAlertLevel,
  WidgetCard,
} from "src/components/Dashboard/DashboardWidgets/WidgetCard"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { getStatefulHomesWithDevices } from "src/data/homes/logic/homeUtil"
import {
  IStatefulHome,
  IStatefulHomeWithDevices,
} from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import ImportantIcon from "src/ui/icons/important-filled.svg"
import { unique } from "src/utils/genericUtil"

export function WidgetUnmonitored() {
  const { org } = useOrganization()
  const { t, langKeys } = useTranslate()
  const limit = 4

  const fetchUnmonitoredHomes = useFetchHomes({
    orgId: org.id,
    filters: { offline_sensors: "all", limit },
  })
  const unmonitoredHomes =
    fetchUnmonitoredHomes.data?.homes.map(
      (h): IStatefulHome => ({
        ...h,
        homeAlertState: "emergency",
      })
    ) || []
  const unmonitoredHomesCount =
    fetchUnmonitoredHomes.data?.paging.total_count || 0

  const fetchUnmonitoredAreas = useFetchHomes({
    orgId: org.id,
    filters: { offline_sensors: "some", limit },
  })
  const unmonitoredAreas =
    fetchUnmonitoredAreas.data?.homes.map(
      (h): IStatefulHome => ({
        ...h,
        homeAlertState: "warning",
      })
    ) || []
  const unmonitoredAreasCount =
    fetchUnmonitoredAreas.data?.paging.total_count || 0

  const homeIds = unique([
    ...unmonitoredHomes.map((h) => h.home_id),
    ...unmonitoredAreas.map((h) => h.home_id),
  ])

  const fetchDevices = useFetchDevices({
    orgId: org.id,
    filter: {
      home_ids: homeIds,
    },
  })
  const devices = fetchDevices.data?.devices || []

  const loading =
    fetchUnmonitoredHomes.isInitialLoading ||
    fetchUnmonitoredAreas.isInitialLoading ||
    fetchDevices.isInitialLoading

  const totalUnmonitoredCount = unmonitoredHomesCount + unmonitoredAreasCount

  const allHomes = [...unmonitoredHomes, ...unmonitoredAreas]
  const homesWithDevices = getStatefulHomesWithDevices(allHomes, devices)

  if (totalUnmonitoredCount < 1) {
    return null
  }

  return (
    <UnmonitoredHomes
      homesWithDevices={homesWithDevices}
      totalCount={totalUnmonitoredCount}
      alertLevel={unmonitoredHomesCount > 0 ? "red" : "orange"}
      label={t(langKeys.unmonitored_homes_plural)}
      loading={loading}
      showLink={totalUnmonitoredCount > limit}
    />
  )
}

function UnmonitoredHomes({
  homesWithDevices,
  totalCount,
  alertLevel,
  label,
  loading,
  showLink,
}: {
  homesWithDevices: IStatefulHomeWithDevices[]
  totalCount: number
  alertLevel: TAlertLevel
  label: string
  loading: boolean
  showLink?: boolean
}) {
  const { t } = useTranslate()
  const { isSuspended } = useDunning()

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <div>
      <WidgetCard
        alertLevel={alertLevel}
        number={totalCount}
        title={label}
        icon={ImportantIcon}
        iconSize={40}
        onClick={handleClick}
        disabled={isSuspended}
      />

      {open && (
        <DeviceListPopover
          title={label}
          emptyStateDescription={t(langKeys.sensors_offline_empty_state)}
          homesWithDevices={homesWithDevices}
          loading={loading}
          anchorEl={anchorEl}
          onClose={handleClose}
          linkTo={
            showLink ? Routes.Homes.location({ offline_sensors: "any" }) : null
          }
        />
      )}
    </div>
  )
}
