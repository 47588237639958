import styled from "styled-components"

import { BATTERY_COLOR_WARN } from "src/data/battery"
import { minimumGray, semanticEmergency, semanticGood } from "src/ui/colors"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function WidgetCard({
  alertLevel,
  onClick,
  icon,
  number,
  title,
  linkTo,
  iconSize,
  disabled = false,
}: {
  alertLevel: TAlertLevel
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  icon?: TIcon
  number: number
  title: string
  linkTo?: string
  iconSize?: number
  disabled?: boolean
}) {
  const Icon = icon || null
  const size = iconSize ?? 32
  const color = disabled
    ? minimumGray
    : {
        green: semanticGood,
        orange: BATTERY_COLOR_WARN,
        red: semanticEmergency,
        disabled: minimumGray,
      }[alertLevel]

  return (
    <MCardInteractive
      onClick={onClick}
      linkTo={linkTo}
      padding="s"
      disabled={disabled}
    >
      <div>
        <MText variant="bodyS" color="secondary">
          {title}
        </MText>
        <TopBox>
          {Icon && (
            <Icon
              width={size}
              height={size}
              fill={color}
              style={{
                color,
                fontSize: 40,
              }}
            />
          )}
          <MText variant="heading1" color="unset" style={{ color }}>
            {disabled ? "-" : number}
          </MText>
        </TopBox>
      </div>
    </MCardInteractive>
  )
}

type TIcon =
  | React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined
      }
    >
  | TIconFunc

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
type TIconFunc = (props: any) => JSX.Element

export type TAlertLevel = "green" | "orange" | "red" | "disabled"

const TopBox = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: ${spacing.M};
  }
`
