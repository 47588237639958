import styled from "styled-components"

import { NoiseMonitoringIcon } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringIcon"
import { OccupancyIcon } from "src/components/Homes/HomeDetails/Occupancy/OccupancyIcon"
import { SecurityAlarmIcon } from "src/components/Homes/HomeDetails/SecurityAlarm/SecurityAlarmIcon"
import { SmokingDetectionIcon } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionIcon"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import {
  IHome,
  IStatefulHomeWithDevices,
  MonitoringType,
} from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TPartialLocation } from "src/router/routeTypes"
import { colorsV2 } from "src/ui/colors"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MLinkStyleProps } from "src/ui/Link/MLink"
import { BodyMixin, MText, SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeTableMini({
  title,
  homesWithDevices,
  linkTo,
}: {
  title: React.ReactNode
  homesWithDevices: IStatefulHomeWithDevices[]
  linkTo: TPartialLocation | string | null
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <MText variant="bodyS" color="secondary" marginBottom={spacing.XS}>
        {title}
      </MText>

      {homesWithDevices.map((home) => (
        <Row key={home.home_id} home={home} devices={home.devices} />
      ))}

      {linkTo && (
        <Footer>
          <InternalLink to={linkTo}>{t(langKeys.events_more)}</InternalLink>
        </Footer>
      )}
    </Box>
  )
}

function Row({ home, devices }: { home: IHome; devices: TDevice[] }) {
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })

  return (
    <InternalLink to={Routes.Home.location(home.home_id)} underline="never">
      <RowBox>
        <HomeTitle>{home.name}</HomeTitle>

        <HomeMonitoringIcons>
          {monitoringAvailable({
            type: MonitoringType.SOUND,
            home,
            devices,
          }) && (
            <NoiseMonitoringIcon
              active={
                home.disturbance_monitoring?.disturbance_monitoring_active
              }
              state={home.disturbance_monitoring?.state_v2}
              iconProps={iconProps}
            />
          )}

          {monitoringAvailable({
            type: MonitoringType.OCCUPANCY,
            home,
            devices,
          }) && (
            <OccupancyIcon
              active={!!home.occupancy?.active}
              status={home.occupancy?.status}
              iconProps={iconProps}
            />
          )}

          {cigaretteSmokeAvailability.available &&
            monitoringAvailable({
              type: MonitoringType.CIGARETTE,
              home,
              devices,
            }) && (
              <SmokingDetectionIcon
                state={home.smoking_detection?.state}
                status={home.smoking_detection?.status}
                iconProps={iconProps}
              />
            )}

          {monitoringAvailable({
            type: MonitoringType.MOTION,
            home,
            devices,
          }) && (
            <SecurityAlarmIcon
              alarmStatus={home.alarm?.alarm_status}
              iconProps={iconProps}
            />
          )}
        </HomeMonitoringIcons>
      </RowBox>
    </InternalLink>
  )
}

const iconProps = { height: 24 }

const Box = styled.div`
  padding: ${spacing.M};
  width: 500px;

  > *:not(:first-child) {
    margin-bottom: ${spacing.M};
    padding-bottom: ${spacing.M};
    border-bottom: 1px solid ${colorsV2.divider};
  }

  > :last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
`

const StyledLink = styled(InternalLink)`
  display: block;
  ${BodyMixin};

  &:hover {
    text-decoration: none;
  }
`

const RowBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing.M};
`

const HomeTitle = styled.div<MLinkStyleProps>`
  flex: 1 1 20ch;
  justify-content: center;
  align-items: center;
  ${SubtitleMixin}
`

const HomeMonitoringIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  gap: ${spacing.S};
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`
