import { useState } from "react"

import { DeviceListPopover } from "src/components/Dashboard/DashboardWidgets/DeviceListPopover"
import { BATTERY_THRESHOLD_BAD, BATTERY_THRESHOLD_WARN } from "src/data/battery"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { LowBatteryFilter } from "src/data/filters/filtersDevice"
import { getStatefulHomesWithDevices } from "src/data/homes/logic/homeUtil"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { BatteryIcon } from "src/ui/BatteryIcon/BatteryIcon"
import { unique } from "src/utils/genericUtil"

import { TAlertLevel, WidgetCard } from "./WidgetCard"

export function WidgetLowBattery() {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const { isSuspended } = useDunning()
  const limit = 4

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      low_battery: true,
      limit,
    },
  })
  const homes = fetchHomes.data?.homes || []
  const homeLowBatteryTotalCount = fetchHomes.data?.paging.total_count || 0

  const fetchDevices = useFetchDevices({
    orgId: org.id,
    filter: {
      home_ids: unique(homes.map((h) => h.home_id)),
    },
  })
  const devices = fetchDevices.data?.devices || []
  const devicesWithLowBattery = devices ?? []

  const lowestChargeDevice = getDeviceWithLowestCharge(devicesWithLowBattery)
  const homesWithDevices = getStatefulHomesWithDevices(homes, devices)

  const loading = fetchHomes.isInitialLoading || fetchDevices.isInitialLoading

  const percent =
    homeLowBatteryTotalCount > 0
      ? lowestChargeDevice?.battery?.percent || 0
      : 100

  const HomesLocation = Routes.Homes.location({ low_battery: true })

  const linkTo =
    homesWithDevices.length > limit
      ? `${HomesLocation.pathname}${HomesLocation.search}`
      : null

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  if (homeLowBatteryTotalCount < 1 || loading) {
    return null
  }

  return (
    <div>
      <WidgetCard
        title={LowBatteryFilter.name(t)}
        alertLevel={getAlertLevel(percent)}
        number={homeLowBatteryTotalCount}
        icon={() => <BatteryIcon percent={percent} width={40} height={40} />}
        onClick={handleClick}
        disabled={isSuspended}
      />

      {open && (
        <DeviceListPopover
          title={t(langKeys.low_battery)}
          emptyStateDescription={t(langKeys.sensors_offline_empty_state)}
          homesWithDevices={homesWithDevices}
          loading={loading}
          anchorEl={anchorEl}
          onClose={handleClose}
          linkTo={linkTo}
        />
      )}
    </div>
  )
}

function getDeviceWithLowestCharge(devices: TDevice[]) {
  return devices.sort(
    (prev, curr) => (prev.battery?.percent || 0) - (curr.battery?.percent || 0)
  )[0]
}

function getAlertLevel(percent: number): TAlertLevel {
  if (percent <= BATTERY_THRESHOLD_BAD) {
    return "red"
  }

  if (percent <= BATTERY_THRESHOLD_WARN) {
    return "orange"
  }

  return "green"
}
